var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.guardar)}}},[_c('div',{staticClass:"iq-edit-list-data"},[_c('iq-card',{scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('h4',{staticClass:"card-title"},[_vm._v("Registro de Stock")])]},proxy:true},{key:"body",fn:function(){return [_c('b-row',{attrs:{"align-v":"center"}},[_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Producto","label-for":"Producto"}},[_c('ValidationProvider',{attrs:{"name":"Producto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"label":"text","placeholder":"Busca un Producto por sku o nombre","filterable":false,"options":_vm.productsOptions},on:{"search":_vm.fetchProducts,"input":function($event){return _vm.productStock()}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin Resultados,"),_c('b-button',{attrs:{"variant":"link"},on:{"click":function($event){return _vm.$bvModal.show('bv-modal-product')}}},[_vm._v("Agregar producto")])]}}],null,true),model:{value:(_vm.productType),callback:function ($$v) {_vm.productType=$$v},expression:"productType"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",class:{ 'col-md-6': _vm.form.type != 1 },attrs:{"label":"Ingreso/Egreso","label-for":"Ingreso/Egreso"}},[_c('ValidationProvider',{attrs:{"name":"Ingreso/Egreso","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"label":"text","disabled":!_vm.productType,"reduce":function (text) { return text.id; },"placeholder":"Seleccionar...","options":_vm.type},on:{"input":function($event){return _vm.ingresoStock()}},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.productType.type === 2 && _vm.form.type == 2),expression:"productType.type === 2 && form.type == 2"}],staticClass:"text-danger"},[_c('small',[_vm._v("Para egresar Bienes con Responsable debe hacerse desde el módulo de Responsables anexando una solicitud para dar de baja el producto.")])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Área","label-for":"Área"}},[_c('ValidationProvider',{attrs:{"name":"Área","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"placeholder":"Seleccionar...","options":_vm.areas,"label":"name","reduce":function (name) { return name.id; },"selectable":function (item) { return (item.id === 1 ? item.disabled : item); }},on:{"input":function($event){return _vm.areaStock()}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin Resultados,"),_c('b-button',{attrs:{"variant":"link","block":""},on:{"click":function($event){return _vm.$refs.createOrUpdate.showModal()}}},[_vm._v("Agregar área")])]}},{key:"option",fn:function(option){return [_vm._t("default",[_vm._v(" "+_vm._s(option.name)+" "),(option.id === 1 ? true : false)?_c('button',{staticClass:"btn btn-link btn-block",on:{"click":function($event){return _vm.$refs.createOrUpdate.showModal()}}},[_vm._v(" Agregar área ")]):_vm._e()])]}}],null,true),model:{value:(_vm.form.area),callback:function ($$v) {_vm.$set(_vm.form, "area", $$v)},expression:"form.area"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Ubicación","label-for":"Ubicación"}},[_c('ValidationProvider',{attrs:{"name":"Ubicación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"placeholder":"Seleccionar...","options":_vm.ubicaciones,"label":"name","reduce":function (name) { return name.id; },"selectable":function (item) { return (item.id === 1 ? item.disabled : item); }},on:{"input":function($event){return _vm.validateProduct()}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin Resultados,"),_c('b-button',{attrs:{"variant":"link","block":""},on:{"click":function($event){return _vm.$refs.create.showModal()}}},[_vm._v("Agregar ubicación")])]}},{key:"option",fn:function(option){return [_vm._t("default",[_vm._v(" "+_vm._s(option.name)+" "),(option.id === 1 ? true : false)?_c('button',{staticClass:"btn btn-link btn-block",on:{"click":function($event){return _vm.$refs.create.showModal()}}},[_vm._v(" Agregar ubicación ")]):_vm._e()])]}}],null,true),model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":!_vm.form.location || !_vm.form.area || !_vm.form.type || !_vm.productType
                    ? 'Cantidad'
                    : ("Stock Actual (" + _vm.stock + ")"),"label-for":"Cantidad"}},[_c('ValidationProvider',{attrs:{"name":"Cantidad","rules":"numeric|required|min:1|max:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{class:errors.length > 0
                        ? ' is-invalid'
                        : _vm.form.type == 2 && _vm.form.quantity > _vm.stock
                        ? ' is-invalid'
                        : '',attrs:{"type":"number","disabled":!_vm.form.location ||
                      !_vm.form.area ||
                      !_vm.form.type ||
                      !_vm.productType,"placeholder":"Cantidad de producto"},model:{value:(_vm.form.quantity),callback:function ($$v) {_vm.$set(_vm.form, "quantity", $$v)},expression:"form.quantity"}}),(_vm.form.type == 2 && _vm.form.quantity > _vm.stock)?_c('div',{staticClass:"text-danger"},[_c('small',[_vm._v("La cantidad ingresada es distinta al stock en existencia")])]):_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),(_vm.form.type == 1 && _vm.productType.type == 2)?_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":"Responsable","label-for":"Responsable"}},[_c('ValidationProvider',{attrs:{"name":"Responsables","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"label":"fullname","reduce":function (fullname) { return fullname.id; },"placeholder":"Seleccionar...","options":_vm.getUsers},model:{value:(_vm.form.user),callback:function ($$v) {_vm.$set(_vm.form, "user", $$v)},expression:"form.user"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{staticClass:"col-md-12",attrs:{"label-for":"Descripción (Opcional)","label":"Descripción (opcional)"}},[_c('ValidationProvider',{attrs:{"name":"Descripción","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-textarea',{class:errors.length > 0 ? ' is-invalid' : '',attrs:{"placeholder":"Ingrese la Descripción","rows":"3","max-rows":"6"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}}),_c('div',{staticClass:"invalid-feedback"},[_c('span',[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1),_c('hr'),_c('b-button',{staticClass:"mr-2",class:{
                disabled: _vm.productType.type === 2 && _vm.form.type == 2,
              },attrs:{"type":"submit","variant":"primary"}},[_vm._v("Guardar Registro")]),_c('b-button',{staticClass:"iq-bg-danger",attrs:{"to":{ name: 'products.stock' },"variant":"none"}},[_vm._v("Cancelar")])]},proxy:true}],null,true)})],1)])]}}])}),_c('b-modal',{attrs:{"size":"lg","id":"bv-modal-product","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" Agregar nuevo producto")]},proxy:true}])},[_c('b-row',[_c('register-product',{attrs:{"origin":"1"},on:{"hide":_vm.hide}})],1)],1),_c('modals',{ref:"createOrUpdate"}),_c('modals-ubication',{ref:"create"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }