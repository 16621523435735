<template>
  <b-container fluid>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(guardar)">
        <div class="iq-edit-list-data">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">Registro de Stock</h4>
            </template>
            <template v-slot:body>
              <b-row align-v="center">
                <!-- PRODUCTOS -->
                <b-form-group
                  class="col-md-6"
                  label="Producto"
                  label-for="Producto"
                >
                  <ValidationProvider
                    name="Producto"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      label="text"
                      v-model="productType"
                      placeholder="Busca un Producto por sku o nombre"
                      :filterable="false"
                      :options="productsOptions"
                      @search="fetchProducts"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                      @input="productStock()"
                    >
                      <template #no-options="{}">
                        Sin Resultados,<b-button
                          variant="link"
                          @click="$bvModal.show('bv-modal-product')"
                          >Agregar producto</b-button
                        >
                      </template>
                    </v-select>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <!-- CANTIDAD -->
                <b-form-group
                  class="col-md-6"
                  :class="{ 'col-md-6': form.type != 1 }"
                  label="Ingreso/Egreso"
                  label-for="Ingreso/Egreso"
                >
                  <ValidationProvider
                    name="Ingreso/Egreso"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      label="text"
                      :disabled="!productType"
                      :reduce="(text) => text.id"
                      v-model="form.type"
                      placeholder="Seleccionar..."
                      :options="type"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                      @input="ingresoStock()"
                    >
                    </v-select>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                    <div
                      class="text-danger"
                      v-show="productType.type === 2 && form.type == 2"
                    >
                      <small
                        >Para egresar Bienes con Responsable debe hacerse desde
                        el módulo de Responsables anexando una solicitud para
                        dar de baja el producto.</small
                      >
                    </div>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group class="col-md-6" label="Área" label-for="Área">
                  <ValidationProvider
                    name="Área"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="form.area"
                      placeholder="Seleccionar..."
                      :options="areas"
                      label="name"
                      :reduce="(name) => name.id"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                      @input="areaStock()"
                      :selectable="
                        (item) => (item.id === 1 ? item.disabled : item)
                      "
                    >
                      <template #no-options="{}">
                        Sin Resultados,<b-button
                          variant="link"
                          block
                          @click="$refs.createOrUpdate.showModal()"
                          >Agregar área</b-button
                        >
                      </template>
                      <template v-slot:option="option">
                        <slot>
                          {{ option.name }}
                          <button
                            v-if="option.id === 1 ? true : false"
                            class="btn btn-link btn-block"
                            @click="$refs.createOrUpdate.showModal()"
                          >
                            Agregar área
                          </button></slot
                        >
                      </template>
                    </v-select>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group
                  class="col-md-6"
                  label="Ubicación"
                  label-for="Ubicación"
                >
                  <ValidationProvider
                    name="Ubicación"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      v-model="form.location"
                      placeholder="Seleccionar..."
                      :options="ubicaciones"
                      label="name"
                      :reduce="(name) => name.id"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                      @input="validateProduct()"
                       :selectable="
                        (item) => (item.id === 1 ? item.disabled : item)
                      "
                    >
                    <template #no-options="{}">
                        Sin Resultados,<b-button
                          variant="link"
                          block
                          @click="$refs.create.showModal()"
                          >Agregar ubicación</b-button
                        >
                      </template>
                      <template v-slot:option="option">
                        <slot>
                          {{ option.name }}
                          <button
                            v-if="option.id === 1 ? true : false"
                            class="btn btn-link btn-block"
                            @click="$refs.create.showModal()"
                          >
                            Agregar ubicación
                          </button></slot
                        >
                      </template>
                    </v-select>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <!-- cantidad -->
                <b-form-group
                  class="col-md-6"
                  :label="
                    !form.location || !form.area || !form.type || !productType
                      ? 'Cantidad'
                      : `Stock Actual (${stock})`
                  "
                  label-for="Cantidad"
                >
                  <ValidationProvider
                    name="Cantidad"
                    rules="numeric|required|min:1|max:4"
                    v-slot="{ errors }"
                  >
                    <b-form-input
                      type="number"
                      :disabled="
                        !form.location ||
                        !form.area ||
                        !form.type ||
                        !productType
                      "
                      v-model="form.quantity"
                      placeholder="Cantidad de producto"
                      :class="
                        errors.length > 0
                          ? ' is-invalid'
                          : form.type == 2 && form.quantity > stock
                          ? ' is-invalid'
                          : ''
                      "
                    >
                    </b-form-input>
                    <div
                      class="text-danger"
                      v-if="form.type == 2 && form.quantity > stock"
                    >
                      <small
                        >La cantidad ingresada es distinta al stock en
                        existencia</small
                      >
                    </div>
                    <div class="invalid-feedback" v-else>
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group
                  class="col-md-6"
                  v-if="form.type == 1 && productType.type == 2"
                  label="Responsable"
                  label-for="Responsable"
                >
                  <ValidationProvider
                    name="Responsables"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-select
                      label="fullname"
                      :reduce="(fullname) => fullname.id"
                      v-model="form.user"
                      placeholder="Seleccionar..."
                      :options="getUsers"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    >
                    </v-select>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group
                  class="col-md-12"
                  label-for="Descripción (Opcional)"
                  label="Descripción (opcional)"
                >
                  <ValidationProvider
                    name="Descripción"
                    rules=""
                    v-slot="{ errors }"
                  >
                    <b-form-textarea
                      placeholder="Ingrese la Descripción"
                      v-model="form.description"
                      rows="3"
                      max-rows="6"
                      :class="errors.length > 0 ? ' is-invalid' : ''"
                    ></b-form-textarea>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </b-row>
              <hr />
              <b-button
                type="submit"
                variant="primary"
                class="mr-2"
                :class="{
                  disabled: productType.type === 2 && form.type == 2,
                }"
                >Guardar Registro</b-button
              >
              <b-button
                :to="{ name: 'products.stock' }"
                variant="none"
                class="iq-bg-danger"
                >Cancelar</b-button
              >
            </template>
          </iq-card>
        </div>
      </form>
    </ValidationObserver>
    <b-modal size="lg" id="bv-modal-product" hide-footer>
      <template #modal-title> Agregar nuevo producto</template>
      <b-row>
        <register-product origin="1" v-on:hide="hide" />
      </b-row>
    </b-modal>
    <modals ref="createOrUpdate" />
    <modals-ubication ref="create" />

  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import { mapActions, mapGetters } from "vuex";
import RegisterProduct from "@/views/Admin/Products/forms/createOrUpdate.vue";
import modals from "@/components/core/modals/AreaModals.vue";
import modalsUbication from '@/components/core/modals/LocationModals'

export default {
  name: "RegisterStock",
  components: {
    RegisterProduct,
     modals,
     modalsUbication
  },
  async created() {
    this.$store.commit("SET_BREADCRUMB", this.items);
    core.index();
    await this.fetchAreas();
    await this.listUbication();
    await this.fetchUsers();
    this.areas = this.getAreaList;
    this.ubicaciones = this.getUbication;
  },
  data() {
    return {
      form: {
        product: "",
        type: "",
        quantity: "",
        description: "",
        location: "",
        user: "",
        area: "",
      },
      productType: "",
      productsSelect: [],
      areas: [],
      ubicaciones: [],
      stock: 0,
      warning: false,

      items: [
        {
          html: "Inicio",
          to: "/",
        },
        {
          text: "Listado de Stock",
          to: "/products/stock",
        },
        {
          text: "Registro de Stock",
          active: true,
        },
      ],
      type: [
        { id: "1", text: "Ingreso de Producto" },
        { id: "2", text: "Egreso De Producto" },
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchAreas: "area/listArea",
      listUbication: "ubication/listUbication",
      fetchUsers: "tool/fetchUsers",
      storeStockProducts: "products/storeStockProducts",
    }),
    async validateProduct() {
      if (this.form.area && this.form.location) {
        let payload = {
          id: this.productType.id,
          area: this.form.area,
          location: this.form.location,
        };
        const res = await this.$store.dispatch(
          "products/productRegister",
          payload
        );
        this.stock = res.stock;
      }
    },
    productStock() {
      this.form.type = "";
      this.form.area = "";
      this.form.location = "";
      this.form.quantity = "";
    },
    ingresoStock() {
      this.form.area = "";
      this.form.location = "";
      this.form.quantity = "";
    },
    areaStock() {
      this.form.location = "";
    },
    async guardar() {
      try {
        const payload = this.form;
        payload.product = this.productType.id;
        await this.storeStockProducts(this.form);
        this.$router.replace("/products/stock");
        core.showSnackbar("success", "Producto Agregado Correctamente");
      } catch (error) {
        const errors = error.response.data.errors;
        for (const key in errors) {
          core.showSnackbar("error", errors[key][0]);
          return false;
        }
      }
    },
    hide(data) {
      this.productType = { id: data.id, text: data.name };
      this.$bvModal.hide("bv-modal-product");
    },
    // Funcion para buscar productos en base a la busqueda
    async fetchProducts(search, loading) {
      if (search && search.length > 2) {
        loading(true);
        const products = await this.$store.dispatch("products/listProducts", {
          search,
        });
        loading(false);
        this.productsSelect = products;
      } else {
        this.productsSelect = [];
      }
    },
  },
  computed: {
    ...mapGetters({
      getAreaList: "area/getAreaList",
      getUbication: "ubication/getUbication",
      getUsers: "tool/getUsers",
    }),
    productsOptions() {
      if (this.productsSelect.length) {
        return this.productsSelect.map(
          ({ id: id, name: text, type: type, stock: stock }) => ({
            id,
            text,
            type,
            stock,
          })
        );
      }
      return [];
    },
  },
};
</script>